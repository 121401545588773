import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Spin, PageHeader, Button, Modal, Col, Row } from "antd";
import { useHistory } from "react-router";

import { propertyActions } from "../../../services/Property/PropertySlice";
import { companyActions } from "../../../services/Company/CompanySlice";

import { columns, tablePagination } from "./constans";
import { ModalCreateProperty } from "./createProperty/ModalCreateProperty";
import { Filter, Report } from "./Components"

export const Property = () => {
  const currentColumn = columns();
  const currentTablePagination = tablePagination();
  const history = useHistory();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [value, setValue] = useState();
  const [isDelete, setIsDelete] = useState(0);
  const [isModalVisibleCreateProperty, setIsModalVisibleCreateProperty] = useState(false);
  const { properties, status, pagination, propertyInfo } = useSelector((state) => state.property);
  const { company, isYampiAdmin } = useSelector((state) => state.user);
  const { page, limit } = pagination;

  useEffect(() => {
    dispatch(companyActions.getCompanys({
      page: 1,
      limit : 40
    }));
  }, []);

  const handleOkCreateProperty = () => {
    setIsModalVisibleCreateProperty(false);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const handleCancel = () => {
    if (current === 4) {
      history.push(`/property/${propertyInfo.id}`);
      setIsModalVisibleCreateProperty(false);
    }
    setIsModalVisibleCreateProperty(false);
  };

  return (
    <div className="user">
      <PageHeader
        className="site-page-header"
        title=""
        style={{ color: '#888888', paddingLeft: 0 }}
      >
        <Filter isDelete={isDelete} setIsDelete={setIsDelete} value={value} setValue={setValue} />
      </PageHeader>
      <Spin spinning={status === "loading"}>
        <Table
          title={() =>
            <>
              <Row justify="space-between">
                <Col flex={1} > <span style={{ color: '#0d0c0c', fontSize: 18, fontWeight: 500, margin: '25px 10px' }}>Lista de Propiedades</span></Col>
                <Col >
                  <Button
                    key="1"
                    type="primary"
                    onClick={() => setIsModalVisibleCreateProperty(true)}
                  >
                    Crear Propiedad
                  </Button>
                </Col>
                <Col>
                  <Report />
                </Col>
              </Row>
            </>
          }
          columns={currentColumn}
          dataSource={properties}
          pagination={currentTablePagination}
          scroll={{ x: 400 }}
        />
      </Spin>
      {isModalVisibleCreateProperty && (
        <Modal
          title={"CREAR PROPIEDAD"}
          visible={isModalVisibleCreateProperty}
          onOk={handleOkCreateProperty}
          onCancel={handleCancel}
          width={1100}
          footer={null}
        >
          <ModalCreateProperty
            handleCancel={handleCancel}
            current={current}
            next={next}
          />
        </Modal>
      )}
    </div>
  );
};
