import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Descriptions, Row, Spin, Typography, List, Timeline, Button, Modal, Table, Col, Select, Form } from "antd";
import { useHistory } from "react-router-dom";
import ApiService from "../../../../common/api/Api"
import { shoppingActions } from "services/Shopping/ShoppingSlice";
import { propertyActions } from "services/Property/PropertySlice";
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { columnsDispersionData, columnsDispersionHistory } from "../constans";
import TableRow from "scenes/Private/StateAccount/pdf_gen/TableRow";
import { formmatterCurrency } from "common/utilities";
import moment from "moment";
import "moment/locale/es";

export const ModalDispersionData = (cart) => {

  const history = useHistory()
  const dispatch = useDispatch()
  const { cartsDispersion } = useSelector(state => state.dispersion);
  const { dispersionData } = useSelector(state => state.shopping);
  const { property } = useSelector(state => state.property);
  const [shopCart, setShopCart] = useState([]);
  const [shopCartSel, setShopCartSel] = useState(0);
  const [propId, setPropId] = useState("");
  const [propName, setPropName] = useState("");
  const [costCon, setConCost] = useState("");
  const [costConDis, setConCostDis] = useState("");
  const [payDate, setPayDate] = useState("");
  const [cartAct, setCartAct] = useState("");
  const currentColumnHistory = columnsDispersionHistory();
  const currentcolumnsDisData = columnsDispersionData();
  const { payload, otraPropiedad } = dispersionData || {};
  const listaDeDispersion = payload?.dispersion || [];
  const historial = payload?.history || [];

  useEffect(() => {
    if (cartAct != cart.id) {
      setShopCart([]);
      dispatch(dispersionActions.setCartsDispersion([]))
      setCartAct(cart.id);
      if (!dispersionData || property != null) {

        let cart_search = {
          "id": cart.cart.shoppingCartId ? cart.cart.shoppingCartId : 0,
          "code": cart.cart.consecutiveDisGen
        }
        if (dispersionData != cart_search) {
          dispatch(shoppingActions.getShoppingDataDis({ cart: cart_search }))
        }

        //se obtiene la info de la propiedad   
        dispatch(propertyActions.getProperty(cart.cart.metadata[0].property))
      } else {
        dispatch(propertyActions.setProperty(null));

        dispatch(propertyActions.getProperty(cart.cart.metadata[0].property))

      }
      if (cart.cart.shoppingCartId == null) {
        dispatch(dispersionActions.getCartsWithoutDis({ contract_id: cart.cart.contractId }))
      }
    }

  }, [dispersionData, dispatch, cart])

  useEffect(() => {
    if (property != null) {
      setPropId(property?.id);
      setPropName(property?.title)
      let costConTotal = 0;
      let costConTotalDis = 0;
      let cost_con = property.contract_active[0];
      cost_con.costs.forEach(async cost => {
        if (cost_con.dis_exclude_admin == 1 && cost.cost_type_id == 2) {
          costConTotalDis += 0;
        } else {
          costConTotalDis += cost.value;
        }
        costConTotal += cost.value;
      })
      setConCost(costConTotal)
      setConCostDis(costConTotalDis)
    } else {
      setPropId("");
      setPropName("")
      setConCost("")
    }

  }, [property])

  useEffect(() => {
    if (cartsDispersion && cartsDispersion.items) {
      if (cartsDispersion.items.length > 0) {
        let data_service = cartsDispersion.items;
        const data_select = data_service.map(item => ({
          value: item.id,
          label: item.id.toString(), // Mostrar el ID en el Select
        }));
        setShopCart(data_select);
      }
    }
  }, [cartsDispersion])


  const cell = {
    width: "100px",
    border: "1px solid #ccc",
    margin: "0px",
    padding: "5px",
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "bold"
  };
  const contenedor = {
    display: "flex",
    flexWrap: "wrap"
  };

  const showDataError = () => {
    const errores = historial.map((dato, index) => {
      let metadata = dato.metadata;
      if (metadata.length == 0) {
        return (
          <Col key={index} className="casilla">
            <p>* {dato.concept}</p>
          </Col>
        );
      } else {
        return null;
      }
    });

    return errores.filter(error => error !== null);
  }
  let showDataGeneratedFees = () => {
    if (dispersionData) {
      let dis_con = dispersionData.payload?.dispersion[0]?.dispersionContext;
      let admin_value = formmatterCurrency(0);
      let val_disount = 0;
      historial.map((dato, index) => {
        if (dato.concept == "Administracion") {
          admin_value = formmatterCurrency(dato.value);
        } else {
          let metadata = dato.metadata;
          if (metadata.length >= 1) {
            if (metadata[0].is_discount == true) {
              val_disount += dato.value;
            }
          }

        }
      });
      let type = dis_con.type;
      let value = (type == 1) ? formmatterCurrency(dis_con.commission) : (100 * dis_con.commission).toFixed(2) + "%";

      let type_com = dis_con.type;
      let value_com = (type_com == 1) ? formmatterCurrency(dis_con.commissionInmo) : (100 * dis_con.commissionInmo).toFixed(2) + "%";

      let type_sec = dis_con.type;
      let value_sec = (type_sec == 1) ? formmatterCurrency(dis_con.commissionSecure) : (100 * dis_con.commissionSecure).toFixed(2) + "%";

      let discount = formmatterCurrency(val_disount);
      let head = (
        <div>
          <Row style={contenedor}>
            <div style={cell}>
              <label></label>
            </div>
            <div style={cell}>
              <label>Recaudo</label>
            </div>
            <div style={cell}>
              <label>Comision Yampi</label>
            </div>
            <div style={cell}>
              <label>Comision Inmobiliaria</label>
            </div>
            <div style={cell}>
              <label>Comision aseguradora</label>
            </div>
            <div style={cell}>
              <label>Administracion</label>
            </div>
            <div style={cell}>
              <label>Descuentos/Adiciones</label>
            </div>
            <div style={cell}>
              <label>Saldo a transferir</label>
            </div>
          </Row>
          <Row style={contenedor}>
            <div style={cell}>
              <label></label>
            </div>
            <div style={cell}>
              <label></label>
            </div>
            <div style={cell}>
              <label>{(value)}</label>
            </div>
            <div style={cell}>
              <label>{(value_com)}</label>
            </div>
            <div style={cell}>
              <label>{(value_sec)}</label>
            </div>
            <div style={cell}>
              <label>{admin_value}</label>
            </div>
            <div style={cell}>
              <label>{discount}</label>
            </div>
            <div style={cell}>
              <label></label>
            </div>
          </Row>
        </div>
      )
      return head;
    }
  }
  const showDataGeneratedProp = () => {
    if (dispersionData) {
      //se obtiene el registro por el tipo de dispersion
      let dis_con_regs = dispersionData.payload?.dispersion;
      let dis_con = dispersionData.payload?.dispersion[0];
      let created = moment(dis_con.createdAt).format("YYYY-MM-DD")
      let com_inmo = 0;
      if (created >= "2025-01-17") {
        dis_con_regs.map((dato_reg, index) => {
          if (dato_reg.type == 0) {
            dis_con = dato_reg;
          }
          if (dato_reg.type == 1) {
            com_inmo = dato_reg.valueTotal;
          }
        });
      } else {
        com_inmo = dis_con.valueInmo;
      }


      let admin_value = formmatterCurrency(0);
      let val_disount = 0;
      historial.map((dato, index) => {
        if (dato.concept == "Administracion") {
          admin_value = formmatterCurrency(dato.value);
        } else {
          let metadata = dato.metadata;
          if (metadata.length >= 1) {
            if (metadata[0].is_discount == true) {
              val_disount += dato.value;
            }
          }

        }
      });
      let discount = formmatterCurrency(val_disount);

      let head = (
        <div>
          <Row style={contenedor}>
            <div style={cell}>
              <label>Propietario</label>
            </div>
            <div style={cell}>
              <label>{formmatterCurrency(dis_con.valueTotal)}</label>
            </div>
            <div style={cell}>
              <label> $ 0.0 </label>
            </div>
            <div style={cell}>
              <label>{formmatterCurrency(com_inmo)}</label>
            </div>
            <div style={cell}>
              <label> $ 0.0 </label>
            </div>
            <div style={cell}>
              <label>{admin_value}</label>
            </div>
            <div style={cell}>
              <label>{discount}</label>
            </div>
            <div style={cell}>
              <label>{formmatterCurrency(dis_con.valuePay)}</label>
            </div>
          </Row>

        </div>
      )
      return head;
    }
  }
  const showDataGeneratedInmo = () => {
    if (dispersionData) {
      let inmo_transfer = formmatterCurrency(0);
      historial.map((dato, index) => {
        if (dato.concept == "Valor a recibir por parte de yampi la inmobiliaria") {
          inmo_transfer = formmatterCurrency(dato.value);
        }
      });
      //se obtiene el registro por el tipo de dispersion
      let dis_con_regs = dispersionData.payload?.dispersion;
      let dis_con = dispersionData.payload?.dispersion[0];
      let created = moment(dis_con.createdAt).format("YYYY-MM-DD")
      let com_inmo = 0;
      let total_init = 0;
      if (created >= "2025-01-17") {
        dis_con_regs.map((dato_reg, index) => { 
          if (dato_reg.type == 1) {
            dis_con = dato_reg;
            total_init = dis_con.valueTotal;
          }
          if (dato_reg.type == 4) {
            com_inmo = dato_reg.valuePay;
          }
        });
      } else {
        com_inmo = dis_con.valueCom;
        total_init = dis_con.valueInmo;
      }


      let head = (
        <div>
          <Row style={contenedor}>
            <div style={cell}>
              <label>Inmobiliaria</label>
            </div>
            <div style={cell}>
              <label>{formmatterCurrency(total_init)}</label>
            </div>
            <div style={cell}>
              <label> {formmatterCurrency(com_inmo)} </label>
            </div>
            <div style={cell}>
              <label> $ 0.0 </label>
            </div>
            <div style={cell}>
              <label>{formmatterCurrency(dis_con.valueSafeSecure)}</label>
            </div>
            <div style={cell}>
              <label> $ 0.0 </label>
            </div>
            <div style={cell}>
              <label> $ 0.0 </label>
            </div>
            <div style={cell}>
              <label>{inmo_transfer} </label>
            </div>
          </Row>

        </div>
      )
      return head;
    }
  }
  const showDataGeneratedAdmin = () => {
    let admin_value = formmatterCurrency(0);
    historial.map((dato, index) => {
      if (dato.concept == "Administracion") {
        admin_value = formmatterCurrency(dato.value);
      }
    });

    let head = (
      <div>
        <Row style={contenedor}>
          <div style={cell}>
            <label>Administracion</label>
          </div>
          <div style={cell}>
            <label>{admin_value}</label>
          </div>
          <div style={cell}>
            <label> $ 0.0 </label>
          </div>
          <div style={cell}>
            <label>$ 0.0</label>
          </div>
          <div style={cell}>
            <label>$ 0.0</label>
          </div>
          <div style={cell}>
            <label> $ 0.0 </label>
          </div>
          <div style={cell}>
            <label> $ 0.0 </label>
          </div>
          <div style={cell}>
            <label>{admin_value}</label>
          </div>
        </Row>

      </div>
    )
    return head;

  }
  const showDiscountsAndAditions = () => {
    if (dispersionData) {
      let dis_con = dispersionData.payload?.dispersion[0];
      let dis_value = 0;
      let dis_custom_value = 0;
      let val_disount = [];
      let val_dis_custom = [];
      val_disount.push(<Col><label> </label></Col>);
      val_disount.push(<Col><label>Desripcion descuentos y adiciones : </label></Col>);
      val_dis_custom.push(<Col><label> </label></Col>);
      val_dis_custom.push(<Col><label>Distribuciones personalizadas del usuario : </label></Col>);
      historial.map((dato, index) => {
        if (dato.concept != "Administracion") {
          let metadata = dato.metadata;
          if (metadata.length >= 1) {
            if (metadata[0].is_discount == true && metadata[0].is_discount_custom == undefined) {
              dis_value += dato.value;
              val_disount.push(<Col><label>{dato.concept} : {dato.value}</label></Col>);
            } else if (metadata[0].is_discount == true && metadata[0].is_discount_custom == true) {
              dis_custom_value += dato.value;
              val_dis_custom.push(<Col><label>{dato.concept} : {dato.value}</label></Col>);
            }
          }
        }
      });
      val_disount.push(<Col><label>TOTAL : {formmatterCurrency(dis_value)}</label></Col>);
      val_dis_custom.push(<Col><label>TOTAL : {formmatterCurrency(dis_custom_value)}</label></Col>);

      let head = (
        <div>
          <Row >
            <div >
              <label>{val_disount}</label> <br />
              <label>{val_dis_custom}</label>
            </div>
          </Row>
        </div>
      )
      return head;
    }
  }

  const handleSubmit = (e) => {
    //console.log("Formulario enviado");
    //console.log("Carrito seleccionado:", shopCartSel);
    if (shopCartSel != 0) {
      let assign_data = {
        "shopping_cart": parseInt(shopCartSel),
        "contract_id": cart.cart.contractId,
        "code_gen": parseInt(cart.cart.consecutiveDisGen)
      }
      //console.log(assign_data);
      dispatch(dispersionActions.assignateCartDis({ assign_data }))
      setShopCart([]);
      dispatch(dispersionActions.setCartsDispersion([]))
    }
  };

  const handleChange = (value, option) => {
    setShopCartSel(value);
  };

  let assignateCart = () => {
    let select_cart = "";
    if (cart.cart.shoppingCartId == null) {
      select_cart = <Form onFinish={handleSubmit} >
        <Form.Item>
          <Select
            showSearch
            value={shopCartSel}
            placeholder={"Carrito disponible"}
            style={{ width: 200 }}
            optionFilterProp="children"
            onChange={handleChange}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={shopCart}
          >
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Asignar
          </Button>
        </Form.Item>
      </Form>

    }
    let head = (
      <div>
        <Row >
          <div >
            <label>{cart.cart.shoppingCartId}</label> <br />
            {select_cart}
          </div>
        </Row>
      </div>
    )
    return head;
  }

  return (
    <Row>
      <Col>
        < >
          <span onClick={() => history.push(`/property/${propId}`)} style={{ color: '#423d3e', marginBottom: '12px', fontSize: '14px', fontWeight: 500, cursor: 'pointer' }}>
            propiedad : {propName}
          </span>
        </ >
        <br />
        < >
          <span>
            Carrito de pago  : {assignateCart()}
          </span>
        </ >
        <br />
        < >
          <span>
            Valor contrato  : {formmatterCurrency(costCon)}
          </span><br />
          <span>
            Valor base de dispersion  : {formmatterCurrency(costConDis)}
          </span>
        </ >
        <br />  <br />
        <Col>
          {showDataError().length > 0 && (
            <>
              <label>Impedimentos para realizar la dispersión:</label>
              <div>{showDataError()}</div>
            </>
          )}
        </Col> <br />
        <Col>
        </Col>
        <Col>
          {showDataGeneratedFees()}
        </Col>
        <Col>
          {showDataGeneratedProp()}
        </Col>
        <Col>
          {showDataGeneratedInmo()}
        </Col>
        <Col>
          {showDataGeneratedAdmin()}
        </Col>
        <label></label> <br />
        <Col>
          {showDiscountsAndAditions()}
        </Col>
      </Col>
    </Row>
  );
};