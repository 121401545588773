import { put, all, takeLatest, select } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import ApiService from '../../common/api/Api';
import { bankActions } from './BankSlice';

function* getBanks({ payload }) {
  yield put(bankActions.onChangeStatus('loading'))
  try {
    //const bank = yield ApiService.get('bank', { country_id: payload })
    const bank = yield ApiService.request('bank/' + payload)
    yield put(bankActions.setBanks(bank))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
    yield put(bankActions.onError(err.toString()))
  }
}

function* getBanksAccountType({ payload }) {
  yield put(bankActions.onChangeStatus('loading'))
  try {
    //const bankAccountType = yield ApiService.get('/bank-account-type', { country_id: payload })
    const bankAccountType = yield ApiService.request('/bank-account/type/' + payload)
    yield put(bankActions.setBankAccountTypes(bankAccountType))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
    yield put(bankActions.onError(err.toString()))
  }
}

function* addBankAccount({ payload }) {
  console.log("🚀 ~ file: bankSaga.js:30 ~ function*addBankAccount ~ data:", payload)
  try {
    yield put(bankActions.onChangeStatus('loading'))
    //yield ApiService.post(`/bank-account/${payload.user_id}/User`, payload.payload) 
    yield ApiService.requestPost(`/bank-account/${payload.user_id}/User`, payload.payload)
    yield put(bankActions.getBankAccount({user_id:payload.user_id,entity :`User`}))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
  }
}

function* getBankAccount({ payload }) {
  try {
    //const response = yield ApiService.get(`/bank-account`)
    const response = yield ApiService.request(`/bank-account/${payload.user_id}/${payload.entity}`)
    yield put(bankActions.setBankAccount(response))
  } catch (err) {
  }
}

function* updateBankAccount({ payload }) {
  console.log("🚀 ~ file: bankSaga.js:46 ~ function*updateBankAccount ~ data:", payload)
  try {
    yield put(bankActions.onChangeStatus('loading'))
    //yield ApiService.put(`/bank-account/${payload.bank_account_id}`, payload.payload) 
    let response_ser = yield ApiService.requestPut(`/bank-account`, payload)
    yield put(bankActions.getBankAccount({ user_id: response_ser.bankAccountableId, entity: `User` }))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
  }
}

function* deleteBankAccount({ payload }) {
  console.log("🚀 ~ file: bankSaga.js:55 ~ function*deleteBankAccount ~ data:", payload.id)
  try {
    yield put(bankActions.onChangeStatus('loading'))
    yield ApiService.requestDelete(`/bank-account/${payload.id}`)
    yield put(bankActions.getBankAccount({ user_id: payload.bankAccountableId, entity: `User` }))
    yield put(bankActions.onChangeStatus('success'))
  } catch (err) {
  }
}

function* ActionWatcher() {
  yield takeLatest(bankActions.getBanks, getBanks)
  yield takeLatest(bankActions.getBanksAccountType, getBanksAccountType)
  yield takeLatest(bankActions.addBankAccount, addBankAccount)
  yield takeLatest(bankActions.getBankAccount, getBankAccount)
  yield takeLatest(bankActions.updateBankAccount, updateBankAccount)
  yield takeLatest(bankActions.deleteBankAccount, deleteBankAccount)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
