import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select, Form, Button } from "antd";
import ApiService from "../../../../../common/api/Api"


export const ModalReport = () => {
    const { companys } = useSelector((state) => state.company);
    const { user } = useSelector((state) => state.auth);
    const { company } = useSelector(state => state.user)

    const [data, setData] = useState([]);

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        if (isAdmin) {
            const data = companys?.items?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setData(data)
        } else {
            const data = company?.map((item) => ({
                value: item?.company_data?.id,
                label: item?.company_data?.name,
            }));
            setData(data)
        }
    }, [companys, user, companys])

    const onFinish = (values) => {
        ApiService.getFile(`/admin/contract_report_list/${values.inmo}/${values.status}`, { name: `Reporte ${data?.find(inmo => inmo.value == values.inmo)?.label} propiedades ${values.status == 0 ? 'inactivas' : 'activas'}.xlsx` })
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (
        <Form
            name="basic"
            labelCol={{ flex: 1 }}
            wrapperCol={{ flex: 1 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            requiredMark={false}
        >
            <Form.Item
                label="Estado propiedad"
                name="status"
                rules={[{ required: true, message: 'Seleccione un estado' }]}
            >
                <Select
                    options={[
                        {
                            value: 1,
                            label: 'Propiedades activas',
                        },
                        {
                            value: 0,
                            label: 'Propiedades inactivas',
                        },
                    ]}
                />
            </Form.Item>

            <Form.Item
                label="Inmobiliaria"
                name="inmo"
                rules={[{ required: true, message: 'Seleccione una inmobiliaria' }]}
            >
                <Select
                    showSearch
                    placeholder={"Inmobiliaria"}
                    style={{ minWidth: 180 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={data}
                />
            </Form.Item>

            <Form.Item wrapperCol={{ flex: "auto" }}>
                <Button type="primary" htmlType="submit">
                    Descargar
                </Button>
            </Form.Item>
        </Form>

    );
};
