import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { formmatterCurrency } from "common/utilities";


const TableRowInmo = ({ item }) => {
    
    const typeTarifa = (item) => {
        let total_inmo = item.dis_reg.valueInmo;
        let total_yampi = item.dis_reg.valueCom;
        let total_secure = item.dis_reg.valueSafeSecure;
        let total = total_inmo + total_yampi + total_secure;
        let total_percent = 0;
        let total_nom = 0;
        (item.dis_reg.dispersionContext.type == 0) ? total_percent += total_yampi : total_nom += total_yampi;
       // (item.dis_reg.dispersionContext.typeInmo == 0) ? total_percent += total_inmo : total_nom += total_inmo;
        //(item.dis_reg.dispersionContext.typeSecure == 0) ? total_percent += total_secure : total_nom += total_secure;

        let label = "Porcentual";
        if (total_nom != 0) {
            label = "Fijo";
        }
        return (
            <>
                <Text style={styles.text}>{label}</Text>
            </>
        );
    };

    const totalPercent1 = (item) => {
        console.log(item);
        let total_inmo = item.dis_reg.dispersionContext.commission;  
        let total_percent = 0;
        let total_nom = 0;
        (item.dis_reg.dispersionContext.type == 0) ? total_percent += total_inmo : total_nom += total_inmo;
        let percent_form =  (total_percent * 100);

        let label =  percent_form+"%" ;
        if (total_nom != 0) {
            label = "$" + formmatterCurrency(total_nom);
        }

        return (
            <>
                <Text style={styles.text}>{label}</Text>
            </>
        );
    };
    const totalPercent2 = (item) => {
        //let total_inmo = item.dis_reg.valueInmo;
        //let total_yampi = item.dis_reg.valueCom;
        //let total_secure = item.dis_reg.valueSafeSecure;
        //let total = total_inmo + total_yampi + total_secure;
//
        //let total_percent = 0;
        //let total_nom = 0;
        //(item.dis_reg.dispersionContext.type == 0) ? total_percent += total_yampi : total_nom += total_yampi; 
        ////(item.dis_reg.dispersionContext.typeInmo == 0) ? total_percent += total_inmo : total_nom += total_inmo;
        ////(item.dis_reg.dispersionContext.typeSecure == 0) ? total_percent += total_secure : total_nom += total_secure;
        //let label = " ";
        //if (total_percent != 0) {
        //    label = "-" + formmatterCurrency(total_percent);
        //}
        //if (total_nom != 0) {
        //    label = "-" + formmatterCurrency(total_nom);
        //}

        let label = " ";
        label = "-" + formmatterCurrency(item.dispersion_fee.yampi_value);

        return (
            <>
                <Text style={styles.text}>{label}</Text>
            </>
        );
    };
    const adminlabel = (item) => {
        let label = "$0";
        if (item != 0) {
            label = "-" + formmatterCurrency(item);
        }
        return (
            <>
                <Text style={styles.text}>{label}</Text>
            </>
        );
    };
    const labeldiscounts = (item) => {
        let label = "$ 0";
        //if (item != 0) {
        //    label = "-" + formmatterCurrency(item);
        //}
        return (
            <>
                <Text style={styles.text}>{label}</Text>
            </>
        );
    };
    const labeldiscountsName = (item) => {
        //let value = (item.discounts.info == "") ? "_ " : item.discounts.info;
        let label = " ";
        //if (item != 0) {
        //    label = "-" + formmatterCurrency(item);
        //}
        return (
            <>
                <Text style={styles.text}>{label}</Text>
            </>
        );
    };
    return (
        <>
        <View style={styles.row} key={1}>
            <View style={styles.cell}>
                <Text style={styles.text}>Honorarios Inmobiliaria</Text>
            </View>
            <View style={styles.cell}>
                <Text style={styles.text}>-</Text>
            </View>
            <View style={styles.cell}>
                <Text style={styles.text}>{formmatterCurrency(item.dis_reg.valueTotal)}</Text>
            </View>
        </View> 
        <View style={styles.row} key={2}>
            <View style={styles.cell}>
                <Text style={styles.text}>Honorarios YAMPI</Text>
            </View>
            <View style={styles.cell}>
                <Text style={styles.text}>
                    <>
                        {typeTarifa(item)} Sobre valor de canon
                    </>
                </Text>
                <Text style={styles.text}>
                    <> 
                        {totalPercent1(item)}
                    </>
                </Text>
            </View>
            <View style={styles.cell}>
                <>
                    {totalPercent2(item)}
                </>
            </View>
        </View>
        <View style={styles.row} key={3}>
            <View style={styles.cell}>
                <Text style={styles.text}>Descuentos</Text>
            </View>
            <View style={styles.cell}>
                <Text style={styles.text}>{labeldiscountsName(item)}</Text>
            </View>
            <View style={styles.cell}>
                <Text style={styles.text}>{labeldiscounts(item.discounts.total_discount)}</Text>
            </View>
        </View>
    </>
    );
};

const styles = {
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'black',
    },
    cellAlone: {
        flex: 12,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cell: {
        flex: 1,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        textAlign: 'center',
        fontSize: 12,
    },
};

export default TableRowInmo; 