import { put, all, takeLatest } from "redux-saga/effects";
import ApiService from "../../common/api/Api";
import { discountsActions } from "./DiscountsSlice";

function* createDiscount({ payload }) {
  try {
    const response = yield ApiService.post(`/admin/dispersion-discount`, payload);
    yield put(discountsActions.setState({ key: 'discountDisModal', value: false }))
    yield put(discountsActions.setState({ key: 'discountDisSelected', value: null }))
    yield put(discountsActions.getDiscountDispersion({ contractId: payload?.contract_id }))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo generar el descuento")
    );
  }
}

function* createDiscountV2({ payload }) {
  try {
    const response = yield ApiService.requestPost(`/dispersion-discount/dispersion-discount`, payload);
    yield put(discountsActions.setState({ key: 'discountDisModal', value: false }))
    yield put(discountsActions.setState({ key: 'discountDisSelected', value: null }))
    yield put(discountsActions.getDiscountDispersionV2({ contractId: payload?.contract_id }))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo generar el descuento")
    );
  }
}


function* updateDiscount({ payload }) {
  try {
     
    const response_up = yield ApiService.put(`/admin/dispersion-discount/${payload.id}` , payload);
    yield put(discountsActions.setState({ key: 'discountDisModal', value: false }))
    yield put(discountsActions.setState({ key: 'discountDisSelected', value: null }))
    yield put(discountsActions.getDiscountDispersionV2({ contractId: payload?.contract_id }))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function* updateDiscountV2({ payload }) {
  try {
     
    const response_up = yield ApiService.requestPut(`/dispersion-discount/dispersion-discount` , payload);
    yield put(discountsActions.setState({ key: 'discountDisModal', value: false }))
    yield put(discountsActions.setState({ key: 'discountDisSelected', value: null }))
    yield put(discountsActions.getDiscountDispersionV2({ contractId: payload?.contract_id }))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function* getDiscountDispersion({ payload }) {
  try {
    const response = yield ApiService.get(`/admin/dispersion-discount/${payload.contractId}`);
    yield put(discountsActions.setDiscountsDis(response))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function* getDiscountDispersionV2({ payload }) {
  try {
    const response = yield ApiService.request(`/dispersion-discount/dispersion-discount/${payload.contractId}`);
    yield put(discountsActions.setDiscountsDis(response.payload))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo encontrar el listado descuento")
    );
  }
}

function* getDiscountContract({ payload }) {
  try {
    const response = yield ApiService.getBackNew(`/contract_adjust_payment/list/${payload.contractId}`);
    yield put(discountsActions.setDiscounts(response?.payload))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function* createDiscountContract({ payload }) {
  console.log("🚀 ~ file: DiscountsSaga.js:54 ~ function*getDiscountContract ~ contractID:", payload)
  try {
    const response = yield ApiService.requestPost(`/contract_adjust_payment/create`, payload);
    yield put(discountsActions.getDiscountContract({ contractId: payload?.contract_id }))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function* updateDiscountContract({ payload }) {
  console.log("🚀 ~ file: DiscountsSaga.js:66 ~ function*getDiscountContract ~ contractID:", payload)
  try {
    const response = yield ApiService.requestPost(`/contract_adjust_payment/update`, payload);
    yield put(discountsActions.setState({ key: 'discountModal', value: false }))
    yield put(discountsActions.setState({ key: 'discountSelected', value: null }))
    yield put(discountsActions.getDiscountContract({ contractId: payload?.contract_id }))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo actualizar el descuento")
    );
  }
}

function*  deleteDiscount({ payload }) {
  try {
    const response_up = yield ApiService.delete(`/admin/dispersion-discount/${payload.reg_id}`);
    const response = yield ApiService.get(`/admin/dispersion-discount/${response_up.contract_id}`);
    yield put(discountsActions.setDiscountsDis(response))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo eliminar el descuento")
    );
  }
}
function*  deleteDiscountV2({ payload }) {
  try {
    const response_up = yield ApiService.requestDelete(`/dispersion-discount/dispersion-discount/${payload.reg_id}`);
    const response = yield ApiService.get(`/admin/dispersion-discount/${response_up.payload.contractId}`);
    yield put(discountsActions.setDiscountsDis(response))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo eliminar el descuento")
    );
  }
}

function*  deleteDiscountContract({ payload }) {
  try {
    const response = yield ApiService.requestDelete(`/contract_adjust_payment/delete/${payload.reg_id}`);
    yield put(discountsActions.getDiscountContract({ contractId: response?.payload?.contract_id }))
  } catch (err) {
    yield put(
      discountsActions.onError("No se pudo eliminar el descuento")
    );
  }
}




function* ActionWatcher() {
  yield takeLatest(discountsActions.createDiscount, createDiscount)
  yield takeLatest(discountsActions.updateDiscount, updateDiscount)
  yield takeLatest(discountsActions.getDiscountDispersion, getDiscountDispersion)
  yield takeLatest(discountsActions.getDiscountContract, getDiscountContract)
  yield takeLatest(discountsActions.createDiscountContract, createDiscountContract)
  yield takeLatest(discountsActions.updateDiscountContract, updateDiscountContract)
  yield takeLatest(discountsActions.deleteDiscount, deleteDiscount)
  yield takeLatest(discountsActions.deleteDiscountContract, deleteDiscountContract)

  yield takeLatest(discountsActions.createDiscountV2, createDiscountV2)
  yield takeLatest(discountsActions.updateDiscountV2, updateDiscountV2) 
  yield takeLatest(discountsActions.deleteDiscountV2, deleteDiscountV2)
  yield takeLatest(discountsActions.getDiscountDispersionV2, getDiscountDispersionV2)

}

export default function* () {
  yield all([ActionWatcher()]);
}

