import React, { useEffect, useState } from "react";
import { Modal, Col, Row, Divider, Button, Form, Input, Select, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import { discountsActions } from "services/Discounts/DiscountsSlice";

export const DiscountModal = ({ visible, discount, contractId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const singleOptions = [
    { value: 0, label: "Siempre" },
  ]
  const monthOptions = [
    { value: 1, label: "Enero" },
    { value: 2, label: "Febrero" },
    { value: 3, label: "Marzo" },
    { value: 4, label: "Abril" },
    { value: 5, label: "Mayo" },
    { value: 6, label: "Junio" },
    { value: 7, label: "Julio" },
    { value: 8, label: "Agosto" },
    { value: 9, label: "Septiembre" },
    { value: 10, label: "Octubre" },
    { value: 11, label: "Noviembre" },
    { value: 12, label: "Diciembre" },
  ];

  useEffect(() => {
    if (discount) {
      setSelectedPeriod(discount.type);
      form.setFieldsValue({
        ...discount,
        recurrent: discount.recurrent === 1,
        type: discount.type === 1,
        is_active: discount.is_active === 1,
        period_applied : discount.period_applied === 0
      });
    }
  }, [discount]);

  const hideModal = () => {
    dispatch(discountsActions.setState({ key: "discountModal", value: false }));
    dispatch(discountsActions.setState({ key: "discountSelected", value: null }));
  };

  const handleFinish = (values) => {
    const payload = {
      contract_id: parseInt(contractId),
      is_active: values.is_active,
      recurrent: values.recurrent,
      type: values.type,
      concept: values.concept,
      value: values.value,
      period_applied: values.period_applied,
    };

    if (discount) {
      dispatch(discountsActions.updateDiscountContract({ con_adj_pay_id: parseInt(discount.id), ...payload }));
    } else {
      dispatch(discountsActions.createDiscountContract(payload));
    }
  };


  const changePeriodAppli = (value) => {
    setSelectedPeriod(value);
    console.log(value);
    // Filtrar opciones en base al valor seleccionado
    if (value) {
      // Ejemplo: Si es "Única vez", mostrar solo "Siempre"
      setFilteredOptions(singleOptions)//.filter((option) => option.value === 0));

    } else {
      // Ejemplo: Si es "Periódico", mostrar meses
      setFilteredOptions(monthOptions)//.filter((option) => option.value !== 0)); // Excluir "Siempre"

    }
    form.setFieldsValue({
      period_applied: value ? 0 : null, // Establecer "Siempre" o limpiar el valor
    });
  };
  const handleMonthChange = (value) => {
    // Actualizar el valor del formulario directamente
    form.setFieldsValue({
      period_applied: value,
    }); 
  };


  const inputStyle = { width: "100%", height: "40px" }; // Altura uniforme para inputs y selects

  return (
    <Modal
      title="Detalle del descuento"
      visible={visible}
      onOk={hideModal}
      onCancel={hideModal}
      width="50%"
      className="ticket-modal"
    >
      <Divider orientation="left" style={{ marginTop: 0 }}>
        Información del Descuento
      </Divider>
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="concept"
              label="Descripción de la novedad"
              rules={[{ required: true, message: "Ingrese el concepto de la novedad" }]}
            >
              <Input size="large" style={inputStyle} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="value"
              label="Valor"
              rules={[{ required: true, message: "Ingrese el valor de la novedad" }]}
            >
              <InputNumber
                style={inputStyle}
                size="large"
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="recurrent"
              label="Frecuencia"
              rules={[{ required: true, message: "Seleccione la frecuencia" }]}
            >
              <Select
                size="large"
                style={inputStyle}
                onChange={changePeriodAppli}
                options={[{ value: true, label: "Periódico" }, { value: false, label: "Única vez" }]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Tipo"
              rules={[{ required: true, message: "Seleccione el tipo" }]}
            >
              <Select
                size="large"
                style={inputStyle}
                options={[{ value: false, label: "Descuento" }, { value: true, label: "Adición" }]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="is_active"
              label="Estado"
              rules={[{ required: true, message: "Seleccione el estado" }]}
            >
              <Select
                size="large"
                style={inputStyle}
                options={[{ value: false, label: "Inactivo" }, { value: true, label: "Activo" }]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="period_applied"
              label="periodo de aplicacion"
              rules={[{ required: true, message: "Seleccione el mes" }]}
            >
              <Select
                size="large"
                style={inputStyle}
                value={selectedMonth}
                onChange={handleMonthChange}
                options={filteredOptions}
                disabled={!filteredOptions.length} // Deshabilitar si no hay opciones
                placeholder="Selecciona un mes"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" htmlType="submit">
            {discount ? "Editar" : "Agregar"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
