import formmatterCurrency from 'common/utilities/formatter/currency'
import { Button } from "antd";
import moment from 'moment'
import 'moment/locale/es'


export const columnsTable = (handleUpdateDiscount, unActivateDiscount) => [
	{
		title: "Concepto",
		dataIndex: "concept",
	},
	{
		title: "Valor",
		dataIndex: "value",
		render: (value) => formmatterCurrency(
			value
		)
	},
	{
		title: "Fecha de creación",
		dataIndex: "createdAt",
		render: (value) => moment(value).format('ll')
	},
	{
		title: "Periodo de aplicacion",
		dataIndex: "period_applied",
		render: (data) => {
			switch (data) {
				case 0:
					data = "Actual"
					break;
				case 1:
					data = "Enero"
					break;
				case 2:
					data = "Febrero"
					break;
				case 3:
					data = "Marzo"
					break;
				case 4:
					data = "Abril"
					break;
				case 5:
					data = "Mayo"
					break;
				case 6:
					data = "Junio"
					break;
				case 7:
					data = "Julio"
					break;
				case 8:
					data = "Agosto"
					break;
				case 9:
					data = "Septiembre"
					break;
				case 10:
					data = "Octubre"
					break;
				case 11:
					data = "Noviembre"
					break;
				case 12:
					data = "Diciembre"
					break; 
			}
			return data;
		}
	},
	{
		title: "Estado",
		dataIndex: "is_active",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Activo"
					break;
				case 0:
					data = "Inactivo"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Frecuencia",
		dataIndex: "recurrent",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Periódico"
					break;
				case 0:
					data = "Única vez"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Tipo",
		dataIndex: "type",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Adicion"
					break;
				case 0:
					data = "Descuento"
					break;
			}
			return data;
		}
	},
	{
		title: "Opciones",
		render: (Row) => {
			return <div><Button className='button' type="primary" onClick={() => handleUpdateDiscount(Row)}>
				Editar
			</Button>
				<br></br><br></br>
				<Button className='button' type="primary" onClick={() => unActivateDiscount(Row)}>
					Eliminar
				</Button>
			</div>
		}
	}
];

export const columnsTableDis = (handleUpdateDiscountDis, unActivateDiscountDis) => [
	{
		title: "Concepto",
		dataIndex: "concept",
	},
	{
		title: "Valor",
		dataIndex: "value",
		render: (value) => formmatterCurrency(
			value
		)
	},
	{
		title: "Fecha de creación",
		dataIndex: "createdAt",
		render: (value) => moment(value).format('ll')
	},
	{
		title: "Periodo de aplicacion",
		dataIndex: "periodApplied",
		render: (data) => {
			switch (data) {
				case 0:
					data = "Actual"
					break;
				case 1:
					data = "Enero"
					break;
				case 2:
					data = "Febrero"
					break;
				case 3:
					data = "Marzo"
					break;
				case 4:
					data = "Abril"
					break;
				case 5:
					data = "Mayo"
					break;
				case 6:
					data = "Junio"
					break;
				case 7:
					data = "Julio"
					break;
				case 8:
					data = "Agosto"
					break;
				case 9:
					data = "Septiembre"
					break;
				case 10:
					data = "Octubre"
					break;
				case 11:
					data = "Noviembre"
					break;
				case 12:
					data = "Diciembre"
					break; 
			}
			return data;
		}
	},
	{
		title: "Estado",
		dataIndex: "isActive",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Activo"
					break;
				case 0:
					data = "Inactivo"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Frecuencia",
		dataIndex: "recurrent",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Periódico"
					break;
				case 0:
					data = "Única vez"
					break;
				default:
					data = "pendiente aprobacion"
					break;
			}
			return data;
		}
	},
	{
		title: "Tipo",
		dataIndex: "type",
		render: (data) => {
			switch (data) {
				case 1:
					data = "Adicion"
					break;
				case 0:
					data = "Descuento"
					break;
			}
			return data;
		}
	},
	{
		title: "Opciones",
		render: (Row) => {
			return <div><Button className='button' type="primary" onClick={() => handleUpdateDiscountDis(Row)}>
				Editar
			</Button>
				<br></br><br></br>
				<Button className='button' type="primary" onClick={() => unActivateDiscountDis(Row)}>
					Eliminar
				</Button>
			</div>
		}
	}
];