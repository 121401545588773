import { put, all, takeLatest, select } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import ApiService from '../../common/api/Api';
import { companyActions } from './CompanySlice';

function* getCompanys({payload}) { 
  try {
    //const company = yield ApiService.get('/admin/company')
    const { page, limit, filter } = payload;
    const company = yield ApiService.request(`/company/company?page=${page}`)
    yield put(companyActions.setCompanys(company)) 
  } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}


function* getGrowCompany({ payload }) {
  try {
    yield put(companyActions.onChangeStatus("loading"));
    const response = yield ApiService.request(
      `/company/get_grow_company/${payload}`,
      "GET"
    );
    let items = response;
    yield put(companyActions.setGrowArray({ items, filter: payload })); // Asegúrate de pasar `filter` correctamente

  } catch (err) {
    yield put(
      companyActions.onError("No se completar la operacion")
    );
  }
}

function* getCompany({ payload }) {
  try {
    yield put(companyActions.onChangeStatus("loading"));
    //const company = yield ApiService.get(`/admin/company/${payload}`)
    const company = yield ApiService.request(`/company/company/${payload}`) 
    //se obtienen las configuraciones
    const response_conf_bill = yield ApiService.request(
      `/company/company-conf-bill/${payload}`,
      "GET"
    ); 
    yield put(companyActions.setCompanyAd(company))
    yield put(companyActions.setCompanyConfAd(response_conf_bill))
    yield put(companyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}


function* getIpcContractList({ payload }) {
  try {
    yield put(companyActions.onChangeStatus("loading"));
    const response = yield ApiService.request(
      `/company/list-ipc-company/${payload.inmo_id}/${payload.month}`,
      "GET"
    );
    let items = response;
    let array = items.payload; 
    yield put(companyActions.setIpcArray({ array, filter: payload })); // Asegúrate de pasar `filter` correctamente
    yield put(companyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}

function* getRenewContractList ({ payload: { page, limit, filter } }) {
  try { 
    yield put(companyActions.onChangeStatus("loading"));
    const response = yield ApiService.requestPost(`/contract_renew/list_contract_renew?page=${page}&limit=${limit}`, {
      ...filter
    });  
    let {items, meta} = response.payload;   
    const pagination = { page:meta.currentPage,total:meta.totalItems, limit, meta }; 
    yield put(companyActions.setIpcArray({ items, pagination, filter: filter })); // Asegúrate de pasar `filter` correctamente
    yield put(companyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}


function* applyRegisterToContract({ payload: {id, page, limit, filter } }) {
  try {
    yield put(companyActions.onChangeStatus("loading"));
    const responseA = yield ApiService.request(
      `/contract_renew/apply_renew_register/${id}`,
      "GET"
    ); 
    const response = yield ApiService.requestPost(`/contract_renew/list_contract_renew?page=${page}&limit=${limit}`, {
      ...filter
    });  
    let {items, meta} = response.payload;   
    const pagination = { page:meta.currentPage,total:meta.totalItems, limit, meta }; 
    yield put(companyActions.setIpcArray({ items, pagination, filter: filter })); // Asegúrate de pasar `filter` correctamente 
    yield put(companyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(
      companyActions.onError("No se completar la operacion")
    );
  }
}

function* setUpdateRenewRegister ({  payload: {update, page, limit, filter } } ) {
  try {  
    yield put(companyActions.onChangeStatus("loading"));
    const responseA = yield ApiService.requestPost(`/contract_renew/update_renew_register`, {
      ...update
    });  
    const response = yield ApiService.requestPost(`/contract_renew/list_contract_renew?page=${page}&limit=${limit}`, {
      ...filter
    });  
    let {items, meta} = response.payload;   
    const pagination = { page:meta.currentPage,total:meta.totalItems, limit, meta }; 
    yield put(companyActions.setIpcArray({ items, pagination, filter: filter })); // Asegúrate de pasar `filter` correctamente 
    yield put(companyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}


function* sendRenewLetter ({payload: {data}}) {
  try {  
    yield put(companyActions.onChangeStatus("loading"));
    const responseA = yield ApiService.requestPost(`/contract_renew/send_renew_letter`,  {data});   
    yield put(companyActions.onChangeStatus("success")); 
   } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}


function* setUpdateCompanyConfigBill ({  payload } ) {
  try {  
    yield put(companyActions.onChangeStatus("loading"));
    const responseA = yield ApiService.requestPost(`/company/company-conf-bill/update`, {
      ...payload
    });  
    //se obtienen las configuraciones
    const response_conf_bill = yield ApiService.request(
      `/company/company-conf-bill/${payload.companyId}`,
      "GET"
    ); 
    yield put(companyActions.setCompanyConfAd(response_conf_bill))
    yield put(companyActions.onChangeStatus("success"));
  } catch (err) {
    yield put(companyActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(companyActions.getCompanys, getCompanys)
  yield takeLatest(companyActions.getGrowCompany, getGrowCompany)
  yield takeLatest(companyActions.getCompany, getCompany)
  yield takeLatest(companyActions.getIpcContractList, getIpcContractList)
  yield takeLatest(companyActions.getRenewContractList, getRenewContractList)
  yield takeLatest(companyActions.applyRegisterToContract, applyRegisterToContract)
  yield takeLatest(companyActions.setUpdateRenewRegister, setUpdateRenewRegister) 
  yield takeLatest(companyActions.sendRenewLetter, sendRenewLetter)
  yield takeLatest(companyActions.setUpdateCompanyConfigBill, setUpdateCompanyConfigBill)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ]);
}
