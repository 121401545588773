import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Layout } from 'antd'
import { useDispatch } from 'react-redux'

import { Header } from './components/Header/Header'
import { Sider } from './components/Sider/Sider'
import { User } from './User/User'
import { Specialist } from './Specialist/Specialist'
import { Shopping } from './Shopping/Shopping'
import { Property } from './Property/Property'
import { Ticket } from './Ticket/Ticket'
import { locationActions } from '../../services/Location/LocationSlice'
import { genreActions } from '../../services/Genre/GenreSlice'
import { specialityActions } from '../../services/Speciality/SpecialitySlice'
import { docIdTypeActions } from '../../services/DocIdType/DocIdTypeSlice'
import { userActions } from '../../services/User/UserSlice'
import { companyActions } from 'services/Company/CompanySlice'
import { PropertyDetail } from './Property/PropertyDetail/PropertyDetail'
import { UserDetail } from './User/UserDetail/UserDetail'
import { Permission } from './Permission/Permission'
import { Home } from './Home/Home'
import { ShoppingDetail } from './Shopping/ShoppingDetail/ShoppingDetail'
import { ContractModal } from './Property/PropertyDetail/Components/ContractModal/ContractModal'
import MenuHeader from './components/MenuHeader/MenuHeader'
import { Dispersion } from './Dispersion/Dispersion'
import { StateAccount } from './StateAccount/StateAccount'
import { PaymentSend } from './PaymentSend/PaymentSend'
import { News } from './News/News'
import { Conciliation } from './Conciliation/Conciliation'
import { SystemBlueprint } from './SysBlueprint/SystemBlueprint'
import { Billing } from './Billing/Billing'
import { BillingDescription } from './Billing/BillingDescription'
import { GrowCompany } from './GrowCompany/GrowCompany'
import { ProcessFiles } from './ProcessFiles/ProcessFiles'
import { Company } from './Company/Company'
import { ContractRenew } from './ContractRenew/ContractRenew'
import { BillingProp } from './BillingProperty/BillingProp'
 

export const Private = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(locationActions.getCountries())
    dispatch(genreActions.getGenres())
    dispatch(specialityActions.getSpecialities())
    dispatch(docIdTypeActions.getDocIdType())
    dispatch(docIdTypeActions.getDocIdType())
    dispatch(userActions.fetchUser())
    dispatch(companyActions.getCompanys({
      page: 1,
      limit:40 
    }));
  }, [])

  return (
    <div className="private">
      <Router>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider />
          <Layout>
            <Header />
            {/* <MenuHeader /> */}
            <Layout.Content style={{ margin: '0px 10px 10px' }}>
              <div style={{ padding: '0 15px 15px' }}>
                <Switch className="h-100">
                  <Route exact path="/" component={User} />
                  <Route exact path="/user" component={User} />
                  <Route exact path="/user/:id" component={UserDetail} />
                  <Route exact path="/specialist" component={Specialist} />
                  <Route exact path="/shopping" component={Shopping} />
                  <Route exact path="/shopping/:id" component={ShoppingDetail} />
                  <Route exact path="/property" component={Property} />
                  <Route exact path="/property/:id" component={PropertyDetail} />
                  <Route exact path="/permission" component={Permission} />
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/tickets" component={Ticket} />
                  <Route exact path="/property/:id/contract/:contract_id" component={ContractModal} />
                  <Route exact path="/dispersion" component={Dispersion} />
                  <Route exact path="/stateAccount" component={StateAccount} />
                  <Route exact path="/payment-send" component={PaymentSend} />
                  {/* <Route exact path="/novedades" component={News} /> */}
                  <Route exact path="/conciliation" component={Conciliation} />
                  <Route exact path="/sysblueprint" component={SystemBlueprint} />
                  <Route exact path="/billing" component={Billing} />
                  <Route exact path="/billing/:id" component={BillingDescription} />
                  <Route exact path="/grow-company" component={GrowCompany} />
                  <Route exact path="/process_files" component={ProcessFiles} />
                  <Route exact path="/company" component={Company} />
                  <Route exact path="/contract_renew" component={ContractRenew} />
                  <Route exact path="/bill-prop" component={BillingProp} />
                </Switch>
              </div>
            </Layout.Content>
          </Layout>
        </Layout>
      </Router>
    </div>
  )
}