import { put, all, takeLatest } from 'redux-saga/effects'
import { locationActions } from './LocationSlice'
import ApiService from '../../common/api/Api'

function* getLocations({ payload }) {
  yield put(locationActions.onChangeStatus('loading'))
  try {
    //const location = yield ApiService.get(`/location/2/${payload}`)
    const location = yield ApiService.request(`/location/2/${payload}`)
    yield put(locationActions.setLocations(location))
    yield put(locationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(locationActions.onError(err.toString()))
  }
}

function* getCountries({ }) {
  yield put(locationActions.onChangeStatus('loading'))
  try {
    //const countries = yield ApiService.get('/country')
    const countries = yield ApiService.request('/location/country')
    yield put(locationActions.setCountries(countries))
    yield put(locationActions.onChangeStatus('success'))
  } catch (err) {
    yield put(locationActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(locationActions.getLocations, getLocations)
  yield takeLatest(locationActions.getCountries, getCountries)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
