import React, { useEffect, useState } from 'react' 
import { Button, Form, Input, Modal, PageHeader, Select, Spin, Table } from 'antd'
import { columns } from './constans';
import { useDispatch, useSelector } from 'react-redux';
import { filesReaderActions } from "services/FilesReader/FilesReaderSlice";
import { CompanyDataCard } from './components/CompanyDataCard/CompanyDataCard';
import { companyActions } from 'services/Company/CompanySlice';

export const Company = () => {

  const [file, setFile] = useState(null);
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth);
  const { companys } = useSelector((state) => state.company);
  const { status, serviceResponse } = useSelector((state) => state.filesReader);
  const [dataInmo, setDataInmo] = useState([]); 

  useEffect(() => {
    const rolesAdmin = ["sysadmin", "admin"];
    const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
    const data = companys?.items?.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    setDataInmo(data)

  }, [companys]) 
  
  const handleSubmit = async (event) => {
     console.log(event.company_id);
     dispatch(companyActions.getCompany(event.company_id));
  };
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif'
    },
    group: {
      marginBottom: '30px',
      border: '1px solid #ddd',
      padding: '15px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9'
    },
    groupTitle: {
      marginBottom: '15px',
      color: '#333',
      fontSize: '20px',
      borderBottom: '2px solid #007bff',
      paddingBottom: '5px'
    },
    item: {
      marginBottom: '20px'
    },
    itemTitle: {
      fontSize: '18px',
      color: '#007bff',
      marginBottom: '10px'
    },
    errorList: {
      listStyleType: 'none',
      paddingLeft: '20px',
      color: '#ff0000'
    },
    errorItem: {
      before: {
        content: '"• "',
        color: '#ff0000',
        fontWeight: 'bold'
      }
    }
  };

  return (
    <div>
      <Form onFinish={handleSubmit}  >
        <Form.Item
          name="company_id"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            showSearch
            placeholder={"Inmobiliaria"}
            style={{ width: 200 }}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={dataInmo}
          />
        </Form.Item> 
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Visualizar
          </Button>
        </Form.Item>
      </Form>
      <CompanyDataCard/>
    </div>
  );
}