import { put, all, takeLatest } from 'redux-saga/effects';
/* import { Alert } from 'react-native'; */
import decode from 'jwt-decode';
import ApiService from '../../common/api/Api'
import { StorageService } from '../../common/storage/storage-service';
import { inventoryActions } from './InventorySlice';
import { getLabelName } from './contants'

function* getSetsTypeAvailable() {
  try {
    const response = yield ApiService.get(`/feature-type`);
    yield put(inventoryActions.setSetsTypeAvailable(response))
  } catch (err) {
    console.log("Error get sets");
  }
}

function* getSetsAvailable() {
  try {
    const response = yield ApiService.get(`/inventory-group`);

    const newReponse = response.map((set) => { return { label: getLabelName(set.name), value: set.id, ...set } })
    yield put(inventoryActions.setSetsAvailable(newReponse))
  } catch (err) {
    console.log("Error get sets");
  }
}

function* getInventoryContract({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/contract-inventory/${payload}`)
    yield put(inventoryActions.setInventory(response))
    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudo obtener el inventario'))
  }
}

function* getInventory({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`/inventory/${payload}`, { contract_id: payload })
    if (response)
      yield put(inventoryActions.getInventoryContract(payload));
    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudo obtener el inventario'))
  }
}

function* getInventoryDuplicate({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    const response = yield ApiService.post(`/duplicate-inventory/${payload}`, { contract_id: payload })
    if (response)
      yield put(inventoryActions.getInventoryContract(payload));
    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudo obtener el inventario'))
  }
}

function* getInventoryItem({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    const response = yield ApiService.request(`/inventory-item/${payload}`)
    yield put(inventoryActions.setInventoryItem(response))
    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudieron obtener los items de inventario'))
  }
}


function* getInventoryTypes() {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/inventory-type`)
    const newReponse = response.map((set) => { return { label: getLabelName(set.name), ...set } })
    yield put(inventoryActions.setInventoryTypes(newReponse))

    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudieron obtener los items de inventario'))
  }
}

function* addInventoryItem({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    yield ApiService.requestPost(`/inventory-item/${payload.inventoryId}`, payload.body)
    const response = yield ApiService.request(`/inventory-item/${payload.inventoryId}`)
    yield put(inventoryActions.setInventoryItem(response))
    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudieron guardar los items en el inventario'))
  }
}

function* filledInventory({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/inventory-filled/${payload}`)

    yield put(inventoryActions.onChangeStatus('success'))
    yield put(inventoryActions.setFullInventory(response.result))
  } catch (err) {
    yield put(inventoryActions.onError('No se ha logrado contactar con el servidor de inventario'))
  }
}

function* updateInventory({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    yield ApiService.post(`/inventory-update/${payload.contract_id}/${payload.inventory_id}/${payload.status}`)
    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudo Actualizar el inventario'))
  }
}

function* generateInventoryAct({ payload }) {
  try {
    yield put(inventoryActions.onChangeStatus('loading'))
    const response = yield ApiService.get(`/inventory-act/${payload.contract_id}/${payload.inventory_id}/${payload.type}`)
    yield put(inventoryActions.onChangeStatus('success'))
  } catch (err) {
    yield put(inventoryActions.onError('No se pudo generar el acta'))
  }
}

function* ActionWatcher() {
  yield takeLatest(inventoryActions.getSetsTypeAvailable, getSetsTypeAvailable)
  yield takeLatest(inventoryActions.getSetsAvailable, getSetsAvailable)
  yield takeLatest(inventoryActions.getInventoryContract, getInventoryContract)
  yield takeLatest(inventoryActions.getInventory, getInventory)
  yield takeLatest(inventoryActions.getInventoryItem, getInventoryItem)
  yield takeLatest(inventoryActions.getInventoryTypes, getInventoryTypes)
  yield takeLatest(inventoryActions.addInventoryItem, addInventoryItem)
  yield takeLatest(inventoryActions.filledInventory, filledInventory)
  yield takeLatest(inventoryActions.updateInventory, updateInventory)
  yield takeLatest(inventoryActions.generateInventoryAct, generateInventoryAct)
  yield takeLatest(inventoryActions.getInventoryDuplicate, getInventoryDuplicate)
}

export default function* InventorySaga() {
  yield all([
    ActionWatcher(),
  ]);
}
