import React, { useEffect, useState } from "react";
import { Select, Button, DatePicker, Form } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentSendActions } from "services/PaymentSend/PaymentSendSlice";
import moment from "moment/moment";
import 'moment/locale/es'
import locale from 'antd/es/locale/es_ES';

export const Filter = () => {

    const dispatch = useDispatch()
    const [dataInmo, setDataInmo] = useState([]);
    const formRef = React.createRef();
    const { companys } = useSelector((state) => state.company);
    const { user } = useSelector((state) => state.auth);
    const { company } = useSelector(state => state.user)

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        if (isAdmin) {
            const data = companys?.items?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setDataInmo(data)
        } else {
            const data = company?.map((item) => ({
                value: item?.company_data?.id,
                label: item?.company_data?.name,
            }));
            setDataInmo(data)
        }
    }, [companys])

    const handleFilter = (e) => {
        const startDate = moment(e.date).startOf('month');
        const endDate = moment(e.date).endOf('month');
        dispatch(PaymentSendActions.getConciliation({ inmo_id: e.inmo_id, startDate: startDate, endDate: endDate }))
    };

    const onReset = () => {
        formRef.current.resetFields();
    };

    return (
        <Form
            name="filterConciliation"
            initialValues={{
                remember: true,
            }}
            layout="inline"
            onFinish={handleFilter}
            autoComplete="off"
            ref={formRef}
        >
            <Form.Item
                name="inmo_id"
                rules={[
                    {
                        required: true,
                        message: 'Seleccione una inmobiliaria',
                    },
                ]}
            >
                <Select
                    showSearch
                    placeholder={"Inmobiliaria"}
                    style={{ width: 200 }}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={dataInmo}
                />
            </Form.Item>

            <Form.Item
                name="date"
                rules={[
                    {
                        required: true,
                        message: 'Ingrese un rango de fechas',
                    },
                ]}
            >
                <DatePicker picker="month" />

                {/* <RangePicker /> */}
            </Form.Item>
            <Form.Item >
                <Button
                    style={{
                        borderRadius: '5px',
                        borderColor: '#d82067',
                        padding: '5px 31px',
                        height: 'auto',
                        fontSize: 'medium',
                    }}
                    type="primary"
                    htmlType="submit"
                >
                    Buscar
                </Button>
            </Form.Item>
            <Form.Item>
                <Button className="btn-default" htmlType="button" onClick={onReset}>
                    Limpiar
                </Button>
            </Form.Item>
        </Form >
    );
};
