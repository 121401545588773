import React, { useEffect, useState } from 'react'
import { Filter } from './sections/Filter'
import { Button, Form, Input, Modal, PageHeader, Select, Spin, Table } from 'antd'
import { columns } from './constans';
import { useDispatch, useSelector } from 'react-redux';
import { filesReaderActions } from "services/FilesReader/FilesReaderSlice";

export const ProcessFiles = () => {

  const [file, setFile] = useState(null);
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth);
  const { companys } = useSelector((state) => state.company);
  const { status, serviceResponse } = useSelector((state) => state.filesReader);
  const [dataInmo, setDataInmo] = useState([]); 

  useEffect(() => {
    const rolesAdmin = ["sysadmin", "admin"];
    const isAdmin = user?.roles?.some((rol) => rolesAdmin.includes(rol.name))
    const data = companys?.items?.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    setDataInmo(data)

  }, [companys])

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    console.log(file);

    if (!file) {
      alert('Por favor selecciona un archivo primero.');
      return;
    }
    let answer = window.confirm("confirme que esta es la informacion a subir");
    if (answer) {
      let data = {
        'file': file,
        'company_id': event.company_id,
      }
      try {
        let response = await dispatch(filesReaderActions.readFileMassive(data));
        console.log('Archivo subido con éxito', response);
      } catch (error) {
        console.error('Error al subir el archivo', error);
      }
    }

  };
  const styles = {
    container: {
      padding: '20px',
      fontFamily: 'Arial, sans-serif'
    },
    group: {
      marginBottom: '30px',
      border: '1px solid #ddd',
      padding: '15px',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9'
    },
    groupTitle: {
      marginBottom: '15px',
      color: '#333',
      fontSize: '20px',
      borderBottom: '2px solid #007bff',
      paddingBottom: '5px'
    },
    item: {
      marginBottom: '20px'
    },
    itemTitle: {
      fontSize: '18px',
      color: '#007bff',
      marginBottom: '10px'
    },
    errorList: {
      listStyleType: 'none',
      paddingLeft: '20px',
      color: '#ff0000'
    },
    errorItem: {
      before: {
        content: '"• "',
        color: '#ff0000',
        fontWeight: 'bold'
      }
    }
  };

  return (
    <div>
      <Form onFinish={handleSubmit}  >
        <Form.Item
          name="company_id"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Select
            showSearch
            placeholder={"Inmobiliaria"}
            style={{ width: 200 }}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={dataInmo}
          />
        </Form.Item>
        <Form.Item>
          <Input type="file" onChange={handleFileChange} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Subir
          </Button>
        </Form.Item>
      </Form>
      <Spin spinning={status === "loading"}>
        <div style={styles.container}>
          {serviceResponse.map((group, groupIndex) => (
            <div key={groupIndex} style={styles.group}>
              <h3 style={styles.groupTitle}>{((groupIndex==0)?'Usuarios':'Propiedades')}</h3>
              {group.map((item, itemIndex) => (
                <div key={itemIndex} style={styles.item}>
                  <h4 style={styles.itemTitle}>{item[0]}</h4>
                  <ul style={styles.errorList}>
                    {item[1].map((error, errorIndex) => (
                      <li key={errorIndex} style={styles.errorItem}>
                        {error}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Spin>
    </div>
  );
}