import { put, all, takeLatest } from 'redux-saga/effects'
import ApiService from '../../common/api/Api'
import { genreActions } from './GenreSlice'

function* getGenres() {
  yield put(genreActions.onChangeStatus('loading'))
  try {
    //const genres = yield ApiService.get('/genre')
    const genres = yield ApiService.request('/genre')
    yield put(genreActions.setGenres(genres))
    yield put(genreActions.onChangeStatus('success'))
  } catch (err) {
    yield put(genreActions.onError(err.toString()))
  }
}

function* ActionWatcher() {
  yield takeLatest(genreActions.getGenres, getGenres)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
