import React, { useEffect, useState } from "react";
import { Radio, Row, Col, Select, Checkbox, Button, DatePicker, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { dispersionActions } from "services/Dispersion/DispersionSlice";
import { companyActions } from "services/Company/CompanySlice";
import moment from "moment/moment";
const { RangePicker } = DatePicker;
export const Filter = ({ }) => {

    const today = new Date();  
    const { pagination, dispersions, filterData } = useSelector(state => state.dispersion)

    const dispatch = useDispatch()
    const { page, limit, total } = pagination;
    const { companys } = useSelector((state) => state.company);
    const [data, setData] = useState([]);
    const { company } = useSelector(state => state.user)
    const { user: authUser } = useSelector((state) => state.auth);


    const [dateRange, setDateRange] = useState([null, null]); 
    const [userEmail, setUserEmail] = useState('');
    const [inmo, setInmo] = useState('Todos');
    const [type, setType] = useState('Todos');

    const [error, setError] = useState('');
    const { user, property } = useSelector((state) => state.property);


    useEffect(() => {
        dispatch(companyActions.getCompanys({
            page: 1,
            limit
          }));
    }, []);

    useEffect(() => {
        const rolesAdmin = ["sysadmin", "admin"];
        const isAdmin = authUser?.roles?.some((rol) => rolesAdmin.includes(rol.name))
        console.log("🚀 ~ data ~ companys:", isAdmin)
        if (isAdmin) {
            const data = companys?.items?.map((item) => ({
                value: item?.id,
                label: item?.name,
            }));
            setData(data)
        } else {
            const data = company?.map((item) => ({
                value: item?.company_data?.id,
                label: item?.company_data?.name,
            }));
            setData(data)
        }
    }, [companys])

    const handleChange = (value, option) => {
        // Obtener el texto del label del option seleccionado
        const selectedLabel = option.label;
        console.log(selectedLabel);
        setInmo(selectedLabel);
    };

    const handleChangeType = (value, option) => {
        if (option.value !== type || option.value == 0) {
            setType(option.value);
        }
    };

    const handleFilter = (e) => {
        e.preventDefault(); 
        if (dateRange[0] == null) {
            let startDate_piv = today.toISOString().split('T')[0]; 
            setDateRange([startDate_piv, startDate_piv]);
        }
        let filterData = {
            period_end: dateRange[1],
            period: dateRange[0],
        };
        if (inmo != "Todos" && inmo != "") {
            if (company[0].company_data.id != 1) {
                filterData.context = company[0].company_data.name;
            } else {
                filterData.context = inmo;
            }
        } else if (company[0].company_data.id != 1) {
            filterData.context = company[0].company_data.name;
        }
        console.log(type);
        if (type !== "Todos" && type !== "") {
            filterData.type_dis = type;
        }
        if (userEmail !== "" && userEmail !== undefined && userEmail !== null) {
            filterData.user_email = userEmail;
        }

        console.log('Filter Data:', filterData);
        dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData }))
        setError('');

    };

    useEffect(() => {
        if (user) {
            if (dateRange[0] == null) {
                let startDate_piv = today.toISOString().split('T')[0]; 
                setDateRange([startDate_piv, startDate_piv]);
            }
            let filterData = {
                period_end: dateRange[1],
                period: dateRange[0],
            };
            if (inmo != "Todos" && inmo != "") {
                filterData.context = inmo;
            }
            if (user != "") {
                filterData.user_email = user.email;
            }
            if (property.id != null) {
                filterData.property_id = property.id;
            }
            dispatch(dispersionActions.getDispersionList({ page, limit, filterData: filterData }))
            setError('');
        }
    }, [user, property])

    useEffect(() => {
        return (() => {
            dispatch(dispersionActions.setDispersions({
                items: [], pagination: {
                    total: 1,
                    page: 1,
                    limit: 20
                }
            }))
        })
    }, [])

    const handleDateChange = (dates) => {
        if (dates) {
            const formattedDates = [
                dates[0] ? dates[0].format('YYYY-MM-DD') : null,
                dates[1] ? dates[1].format('YYYY-MM-DD') : null
              ];
              setDateRange(formattedDates);
        } else {
            setDateRange([null, null]);
        }
    };


    const { Option } = Select;
    return (
        <form onSubmit={handleFilter} style={{ padding: "16px 26px", flexDirection: "row-reverse", display: "flex" }}>

            <Row gutter={10} padding>
                <Col>
                    {/*         <label>
                        <input
                            style={{
                                padding: "5px 31px",
                                height: "auto",
                                fontSize: "medium",
                            }}
                            type="date"
                            value={startDate}
                            defaultValue={formattedDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </label>
                    <DatePicker value={startDate ? moment(startDate) : null} onChange={setStartDate} placeholder="Fecha" />
                     */}
                    <RangePicker
                        value={dateRange[0] && dateRange[1] ? [moment(dateRange[0]), moment(dateRange[1])] : []}
                        onChange={handleDateChange}
                        placeholder={['Fecha de inicio', 'Fecha de fin']}
                    />
                </Col>
                <Col>
                    <Select
                        placeholder={"Tipo de pago"}
                        notFoundContent={null}
                        style={{ width: 200 }}
                        onChange={handleChangeType}
                        value={type}
                    >
                        <Option value={0}>
                            Pago a Propietario
                        </Option>
                        <Option value={1}>
                            Pago a inmobiliaria
                        </Option>
                        <Option value={2}>
                            Pago a administracion
                        </Option>
                        <Option value={3}>
                            Dispersion personalizada
                        </Option>
                        <Option value={4}>
                            Pago a Yampi
                        </Option>
                    </Select>
                </Col>
                <Col>
                    <Select
                        showSearch
                        value={inmo}
                        placeholder={"Inmobiliaria"}
                        style={{ width: 200 }}
                        optionFilterProp="children"
                        onChange={handleChange}
                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={data}
                    >
                    </Select>
                </Col>
                <Col>
                    {/*          <label>
                        <input
                            style={{
                                padding: "5px 31px",
                                height: "auto",
                                fontSize: "medium",
                            }}
                            type="string"
                            name="userEmail"
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                    </label> */}
                    <Input onChange={(e) => setUserEmail(e.target.value)} placeholder="Correo propietario"></Input>
                </Col>
                <Col>
                    <Button
                        style={{
                            borderRadius: '5px',
                            borderColor: '#d82067',
                            padding: '5px 31px',
                            height: 'auto',
                            fontSize: 'medium',
                             
                        }}
                        type="primary"
                        htmlType="submit"
                    >
                        Buscar
                    </Button>
                </Col>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </Row>
        </form >
    );
};
