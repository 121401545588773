import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import {
  Row,
  Button,
  Descriptions,
  Spin,
  Collapse,
  Table,
  Image,
  Modal,
  Menu,
  Dropdown,
  Col
} from "antd";
import { ArrowLeftOutlined, EyeOutlined, UploadOutlined, RedoOutlined } from "@ant-design/icons";

import { apiUrl } from "../../../../common/config/Environments";
import { propertyActions } from "../../../../services/Property/PropertySlice";
import { userActions } from "../../../../services/User/UserSlice";
import { ticketActions } from "../../../../services/Ticket/TicketSlice";
import { TicketModal } from "./TicketModal/TicketModal";
import { notificationActions } from "../../../../services/Notification/NotificationSlice";
import { ShowButton } from "../../components/ShowButton/ShowButton";
import EditUser from "../EditUser/EditUser";
import AddCompanyRol from "./components/AddCompanyRol";
import ChangePassword from "./components/ChangePassword";
import Drop from "./components/Dropdown";
import { UserInfo } from './sections';
import AddBankAccount from "./components/AddBankAccount";
import { FiDelete, FiEdit } from "react-icons/fi";
import { bankActions } from "services/Bank/BankSlice";
import { twilioActions } from "services/Twilio/TwilioSlice";

const { Panel } = Collapse;

export const UserDetail = () => {
  const [ticket, setTicket] = useState();

  const { id: user_id } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const { userProperties } = useSelector((state) => state.property);
  const { bankAccount,status_load } = useSelector((state) => state.bank);
  const [isModalEditUser, setIsModalEditUser] = useState(false);
  const [isModalPassword, setIsModalPassword] = useState(false);
  const [isModalCompany, setIsModalCompany] = useState(false);
  const [isModalBank, setIsModalBank] = useState(false);
  const { user, status, stateCount } = useSelector((state) => state.user);
  const { notifications, pagination } = useSelector((state) => state.notification);
  const [notificationsList, setNotificationsList] = useState([]);

  const {
    userTickets,
    userTicketsPagination,
    status: ticketStatus,
  } = useSelector((state) => state.ticket);

  const { page, limit, total } = userTicketsPagination;

  useEffect(() => {
    dispatch(userActions.getUser(user_id));
    dispatch(ticketActions.getUserTickets({ user_id, limit, page }));
    dispatch(propertyActions.getUserProperties(user_id));
    dispatch(notificationActions.getNotifications({ userId: user_id, limit: 5, page: 1 }));
    dispatch(userActions.getStateCount(user_id));
    dispatch(bankActions.getBankAccount({user_id:user_id,entity:'User'}));
    return onUnmount;
  }, []);

  useEffect(() => {
    fetchNotifications();
  }, [notifications]); // Llama una vez al montar el componente
  
  const fetchNotifications = async () => {
    try { 
      setNotificationsList(notifications); 
    } catch (error) {
      console.error("Error cargando notificaciones:", error);
    }
  };

  const onUnmount = () => {
    dispatch(userActions.setUser());
    dispatch(ticketActions.setUserTickets());
    dispatch(propertyActions.setUserProperties());
  };

  const formatter = (input) =>
    new Intl.NumberFormat("en", {
      style: "currency",
      currency: "COP",
      minimumFractionDigits: 0,
    })
      .format(input)
      .replace("COP", "$")
      .replace(/,b/gi, ".");

  const propertiesColumns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Ubicación",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Participación",
      dataIndex: "participation",
      key: "participation",
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "id",
      width: "10%",
      render: (id) => (
        <ShowButton
          onClick={() => history.push(`/property/${id}`)}
          size="small"
        />
      ),
    },
  ];

  const ticketsColumns = [
    {
      title: "Título",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Creación",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "id",
      width: "10%",
      render: (id, record) => (
        <ShowButton onClick={() => setTicket(record)} size="small" />
      ),
    },
  ];

  const paymentsColumns = [
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("LL"),
    },
    {
      title: "Valor",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (total_amount) => formatter(total_amount),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      /* sorter: (a, b) => a.status.length - b.status.length, */
    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "id",
      width: "10%",
      render: (id) => (
        <ShowButton
          onClick={() => history.push(`/shopping/${id}`)}
          size="small"
        />
      ),
    },
  ];


  const checkMessage = (data) => {
    let answer = window.confirm("Desea verificar el estado del mensaje?");
    if (answer) {
      dispatch(twilioActions.checkMessageWhatapp({ messageId: data }))
      dispatch(notificationActions.getNotifications({ userId: user_id, limit: 5, page: 1 }));
    }
  }

  const notificationsColumns = [
    /* {
       title: "Id",
       dataIndex: "id",
       key: "id",
     },*/
    {
      title: "Titulo",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => moment(createdAt).format("LL"),
    },
    {
      title: "Mensaje",
      dataIndex: "",
      key: "message",
      render: (value) => {
        console.log("🚀 ~ UserDetail ~ value:", value)
        if (value.message) {
          return value.message
        } else {
          return value.metadata.message_whatsapp
        }
      }
    },
    {
      title: "Canal",
      dataIndex: "metadata",
      key: "metadata",
      render: (metadata) => (metadata?.type),
    },
    {
      title: "Status",
      dataIndex: "",
      key: "metadata",
      render: (value) => {
        var ob_meta = value.metadata;
        if (ob_meta?.type == "whatsapp") {
          if (ob_meta?.response_service?.status != 400) { 
            let label = "";
            let button_check = <>
              <ShowButton icon={<RedoOutlined />} onClick={() => checkMessage(value.id)} />

            </>
            switch (ob_meta?.response_service?.status) {
              case "queued":
              case "sent":
                label = <>Enviado {button_check}</>;
                break;
              case "failed":
                label = "Fallido";
                break;
              case "received":
                label = <>Recibido {button_check}</>;
                break;
              case "delivered":
                label = <>Entregado {button_check}</>;
                break;
              case "read":
                label = <>Leido </>;
                break;
            }
            return label;
          } else {
            return "";
          }

        }
      },
    },


  ];

  const StateCount = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Fecha Entrega",
      dataIndex: "date_deliver",
      key: "date_deliver",
      render: (date_deliver) => moment(date_deliver).format("LL"),
    },
    {
      title: "Fecha Recepción",
      dataIndex: "date_receive",
      key: "date_receive",
      render: (date_receive) => moment(date_receive).format("LL"),
    },
    {
      title: "Concepto",
      dataIndex: "concept",
      key: "concept",
    },
    {
      title: "Valor",
      dataIndex: "value",
      key: "value",
      render: (value) => formatter(value),
    },
  ];


  // seccion de funciones de cuenta bancaria

  const deleteBank = (bank_account_data) => {
    let answer = window.confirm("Desea eliminar esta cuenta de banco, no podra recuperar los datos luego?");
    if (answer) {
      dispatch(bankActions.deleteBankAccount({bankAccountableId: user?.id , id: bank_account_data.id }));
    }
  };

  const setDefaultBank = (bank_account_data) => {
    let answer = window.confirm("Desea asignar esta cuenta como la cuenta por defecto?");
    if (answer) {
      let payload = {
        bankAccountable_id : user?.id,
        bankAccountable_type : "User",
        number: bank_account_data.number,
        is_default: true,//1,
        bank_account_type: { "id": bank_account_data.bankAccountType.id },
        bank: { "id": bank_account_data.bank.id },
        id: bank_account_data.id 
      }
      dispatch(bankActions.updateBankAccount(payload));
    }
  };
  const bankAccountColumns = [
    {
      title: "Banco",
      dataIndex: "bank",
      key: "name",
      render: (text, record) => record.bank.name,
    },
    {
      title: "Numero",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Tipo",
      dataIndex: "bank_account_type",
      key: "name",
      render: (text, record) => {
        console.log(record);
        //record.bank_account_type.name == 'checking'
        //  ? 'Corriente'
        //  : 'Ahorros',
        let data = '';
        switch (record.bankAccountType.name) {
          case 'savings':
            //case 3:
            data = "Ahorros"
            break;
          case 'checking':
            //case 4:
            data = "Corriente"
            break;
          case 'agreement':
            //case 6:
            data = "Convenio"
            break;
        }
        return data;
      }
    },
    {
      title: "Por defecto",
      dataIndex: "isDefault",
      key: "isDefault",
      render: (is_default) =>
        is_default
          ? 'Activo'
          : 'inactivo',

    },
    {
      title: "Acciones",
      key: "action",
      dataIndex: "",
      width: "10%",
      render: (bank_accout) => (
        <>
          <ShowButton
            icon={<FiDelete />}
            onClick={() => {
              deleteBank(bank_accout);
            }}
            size="small"
          />
          <ShowButton
            icon={<FiEdit />}
            onClick={() => {
              setDefaultBank(bank_accout);
            }}
            size="small"
          />
        </>
      ),
    },
  ];

  const handlePagination = (page, limit) => {
    dispatch(ticketActions.getUserTickets({ user_id, limit: pagination.limit, page: pagination.page }));
  };

  const handlePaginationNotification = (page, limit) => {
    dispatch(notificationActions.getNotifications({ page, limit, userId: user_id }));
  }

  const handleOkDiscount = () => {
    setIsModalEditUser(false);
    setIsModalPassword(false);
    setIsModalCompany(false);
    setIsModalBank(false);
  };

  const handleCancel = () => {
    setIsModalEditUser(false);
    setIsModalCompany(false);
    setIsModalPassword(false);
    setIsModalBank(false);
  };

  const tablePagination = {
    onChange: handlePagination,
    onShowSizeChange: handlePagination,
    hideOnSinglePage: true,
    current: page,
    pageSize: limit,
    total,
  };

  return (
    <div className="user-detail">
      <Row gutter={32}>
        <Col lg={8} xl={7} xxl={8}>
          <UserInfo />
        </Col>
        <Col lg={16} xl={16} xxl={16}>
          <Spin spinning={status === "loading" || status_load === "loading"}>
            <Collapse defaultActiveKey={"0"} bordered={true}>
              <Panel header="INFORMACIÓN" key="0">
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32, xl: 32, xxl: 32 }}
                  style={{ width: "100%", marginLeft: "0px" }}
                >
                  <Descriptions
                    title="Información de Usuario"
                    bordered
                    style={{ width: "100%" }}
                    column={{ xs: 1, sm: 2, md: 1, lg: 2, xl: 2, xxl: 2 }}
                    extra={
                      <Drop
                        setIsModalCompany={setIsModalCompany}
                        setIsModalEditUser={setIsModalEditUser}
                        setIsModalPassword={setIsModalPassword}
                      />
                    }
                  >
                    <Descriptions.Item label="NOMBRE">{user?.name}</Descriptions.Item>
                    <Descriptions.Item label="APELLIDO">{user?.lastname}</Descriptions.Item>
                    <Descriptions.Item label="CORREO">{user?.email}</Descriptions.Item>
                    <Descriptions.Item label="GÉNERO">{user?.genre}</Descriptions.Item>
                    <Descriptions.Item label="TELÉFONO">{user?.phone?.number}</Descriptions.Item>
                    <Descriptions.Item label="UBICACIÓN">{user?.location}</Descriptions.Item>
                    <Descriptions.Item label="ROLES">
                      {user?.roles?.map((item, index) => (
                        <span key={index}>
                          {item.name}
                          {index < user.roles.length - 1 && ", "}
                        </span>
                      ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="COMPAÑÍA">
                      {user?.company?.map((item, index) => (
                        <span key={index}>
                          {item.company_data.name}
                          {index < user.company.length - 1 && ", "}
                        </span>
                      ))}
                    </Descriptions.Item>
                  </Descriptions>
                </Row>
              </Panel>
              <Panel header="CUENTAS BANCARIAS" key="1">
                {bankAccount?.length > 0 && (
                  <Table
                    columns={bankAccountColumns}
                    dataSource={bankAccount}
                    pagination={{ pageSize: 5 }}
                    size="small"
                    bordered={true}
                  />
                )}
                <Button type="primary" onClick={() => setIsModalBank(true)} style={{ width: '100%' }}>
                  Agregar Cuenta bancaria
                </Button>
              </Panel>

              {userProperties?.length > 0 && (
                <Panel header="PROPIEDADES" key="1">
                  <Table
                    columns={propertiesColumns}
                    dataSource={userProperties}
                    pagination={{ pageSize: 5 }}
                    size="small"
                    bordered={true}
                  />
                </Panel>
              )}
              {userTickets?.length > 0 && (
                <Panel header="MANTENIMIENTOS" key="2">
                  <Spin spinning={ticketStatus === "ticketsLoading"}>
                    <Table
                      columns={ticketsColumns}
                      dataSource={userTickets}
                      pagination={tablePagination}
                      size="small"
                    />
                  </Spin>
                </Panel>
              )}
              {user?.payments?.length > 0 && (
                <Panel header="PAGOS" key="3">
                  <Table
                    columns={paymentsColumns}
                    dataSource={user?.payments}
                    pagination={{ pageSize: 5, hideOnSinglePage: true }}
                    size="small"
                    bordered={true}
                  />
                </Panel>
              )}
              {notifications?.length > 0 && (
                <Panel header="NOTIFICACIONES" key="4">
                  <Table
                    columns={notificationsColumns}
                    dataSource={notificationsList}
                    pagination={{ current: pagination.page, pageSize: pagination.limit, total: pagination.total, hideOnSinglePage: true, onChange: handlePaginationNotification, }}
                    size="small"
                    bordered={true}
                  />
                </Panel>
              )}
              {stateCount?.length > 0 && (
                <Panel header="ESTADO DE CUENTA" key="5">
                  <Table
                    columns={StateCount}
                    dataSource={stateCount}
                    pagination={{ pageSize: 5, hideOnSinglePage: true }}
                    size="small"
                    bordered={true}
                  />
                </Panel>
              )}
            </Collapse>
          </Spin>
        </Col>
      </Row>
      <TicketModal ticket={ticket} setTicket={setTicket} />
      <Modal
        title={"EDITAR USUARIO"}
        visible={isModalEditUser}
        onOk={handleOkDiscount}
        onCancel={handleCancel}
        footer={null}
        width={"70%"}
      >
        <EditUser handleCancel={handleCancel} />
      </Modal>
      <Modal
        title={"AGREGAR ROL"}
        visible={isModalCompany}
        onOk={handleOkDiscount}
        onCancel={handleCancel}
        footer={null}
        width={"35%"}
      >
        <AddCompanyRol handleCancel={handleCancel} user={user} />
      </Modal>
      <Modal
        title={"CAMBIAR CONTRASEÑA"}
        visible={isModalPassword}
        onOk={handleOkDiscount}
        onCancel={handleCancel}
        footer={null}
        centered
        width={"25%"}
      >
        <ChangePassword handleCancel={handleCancel} user={user} />
      </Modal>
      <Modal
        title={"AGREGAR CUENTA DE BANCO"}
        visible={isModalBank}
        onOk={handleOkDiscount}
        destroyOnClose={true}
        onCancel={handleCancel}
        footer={null}
        width={"35%"}
      >
        <AddBankAccount handleCancel={handleCancel} bankAccount={user?.bank_account[0]} user_id={user?.id} />
      </Modal>
    </div>
  );
};
