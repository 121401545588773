import React, { useEffect, useState } from "react";
import { Steps, Input, Form, Select, Radio, Button, InputNumber } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import InfoBasic from "./components/InfoBasic";
import InfoSecond from "./components/InfoSecond";
import { locationActions } from "../../../../services/Location/LocationSlice";
import { companyActions } from "../../../../services/Company/CompanySlice";
import { userActions } from "../../../../services/User/UserSlice";
import StepNotification from "./components/StepNotification";
import Email from "./components/Email";

const CreateUser = ({ id, handleCancel }) => {
  const { Step } = Steps;
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [current, setCurrent] = useState(0);
  const [selectCity, setSelectCity] = useState();
  const [selectCityBirth, setSelectCityBirth] = useState();
  const { locations } = useSelector((state) => state.location);
  const { id: user_id } = useParams();
  const { user } = useSelector((state) => state.user);
  const { companys } = useSelector((state) => state.company);

  useEffect(() => {
    dispatch(companyActions.getCompanys({
      page: 1,
      limit : 40
    }));
  }, []);

  useEffect(() => {
    dispatch(companyActions.getCompanys({
      page: 1,
      limit : 40
    }));
  }, [selectCity, selectCityBirth]);

  useEffect(() => {
  }, [companys]);

  const next = (values) => {
    if (current === 2) {
      let localization = {
        location_id: selectCity[0].id,
        latitude: selectCity[0].latitude,
        longitude: selectCity[0].longitude,
        enity_name: "user",
        entity_id: 1,
        address: values.direction,
      };

      let phone = {
        enity_name: "user",
        entity_id: 1,
        number: values.contac,
        country_id: 2,
        phone_type_id: 2,
      };

      let docid = {
        enity_name: "user",
        entity_id: 2,
        docid_type_id: data.docid_type_id,
        code: data.code.toString(),
        issue_date: data.issue_date.format("YYYY-MM-DD"),
        issue_location: selectCityBirth[0].fullname,
      };
      let profile = {
        name: data.name,
        lastname: data.lastname,
        country_id: 2,
        birthday: data.birthday.format("YYYY-MM-DD"),
        genre_id: data.genere_id,
        per_trib_type_id: data.per_trib_type_id,
        birth_location: selectCityBirth[0].fullname,
      };
      let payload = {
        email: data.Email,
        password: data.password,
        role_id: values.role_id,
        profile,
        docid,
        phone,
        localization,
      };
      dispatch(userActions.createUser(payload));
      setCurrent(current + 1);
    } else {
      setCurrent(current + 1);
      setData({ ...data, ...values });
    }
  };

  const getLocationOnSearch = (e) => {
    if (e.length > 4) dispatch(locationActions.getLocations(e));
  };

  const getLocationOnChange = (e) => {

    const city = locations.filter((location) => location.id == e);

    setSelectCity(city);
  };

  return (
    <>
      <Steps current={current} size="">
        <Step title="E-mail" />
        <Step title="Datos Generales" />
        <Step title="Datos Secundarios" />
        <Step title="Confirmación" />
      </Steps>
      <div>
        {
          {
            0: <Email next={next} />,
            1: (
              <InfoBasic
                next={next}
                setSelectCity={setSelectCity}
                getLocationOnSearch={getLocationOnSearch}
                getLocationOnChange={getLocationOnChange}
                setSelectCityBirth={setSelectCityBirth}
              />
            ),
            2: (
              <InfoSecond
                next={next}
                setSelectCity={setSelectCity}
                getLocationOnSearch={getLocationOnSearch}
                getLocationOnChange={getLocationOnChange}
              />
            ),
            3: <StepNotification handleCancel={handleCancel} />,
          }[current]
        }
      </div>
    </>
  );
};

export default CreateUser;
